import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from "./navbar"
import Footer from "./footer"
import "./layout.scss"

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
					author
					authorLink
        }
      }
    }
  `)

	const siteData = data.site.siteMetadata

	return (
		<div className="container-fluid p-0">
			<Navbar siteTitle={siteData.title || `Title`} />
			<main >
				<main>{children}</main>
			</main>
			<Footer siteData={siteData} />
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
