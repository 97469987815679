import * as React from "react"
import PropTypes from "prop-types"

const Footer = ({ siteData }) => {

	const { title, author, authorLink } = siteData
	const yearNow = new Date().getFullYear()
	return (
		<footer className="footer fixed-bottom bg-secondary p-2 p-md-3 text-center">
			<div className="copyright">Copyright &#169; {yearNow} &#183; {title}</div>
			<div className="copyright">
				a property of <a href={authorLink} target="_blank" rel="noreferrer">{author}</a>
			</div>
		</footer>
	)
}

Footer.propTypes = {
	siteData: PropTypes.object,
}

Footer.defaultProps = {
	siteTitle: {},
}

export default Footer
